<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <v-btn :to="`/${organizationUrl}`" color="primary" plain>
          <v-icon left>mdi-arrow-left</v-icon>Revenir au planning
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="6">
        <v-card class="elevation-1">
          <v-toolbar color="deep-orange lighten-3" dark flat>
            <v-toolbar-title>{{
              $helpers.string.capitalizeI18N("register-name")
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="message" :type="successful ? 'success' : 'error'">{{
              message
            }}</v-alert>
            <v-form v-if="!successful" ref="form">
              <v-container>
                <v-row>
                  <v-text-field :label="$helpers.string.capitalizeI18N('first-name', {
                    required: true,
                  })
                    " v-model="client.firstName" name="firstName" prepend-icon="mdi-account" type="text"
                    :rules="$helpers.rule.notEmpty()" />
                </v-row>
                <v-row>
                  <v-text-field :label="$helpers.string.capitalizeI18N('last-name', {
                    required: true,
                  })
                    " v-model="client.lastName" name="lastName" prepend-icon="mdi-account" type="text"
                    :rules="$helpers.rule.notEmpty()" />
                </v-row>
                <v-row>
                  <v-text-field :label="$helpers.string.capitalizeI18N('email', {
                    required: true,
                  })
                    " v-model="client.email" name="email" prepend-icon="mdi-email" type="email"
                    :rules="$helpers.rule.notEmpty()" />
                </v-row>
                <v-row>
                  <v-text-field :type="showPassword ? 'text' : 'password'" :label="$helpers.string.capitalizeI18N('password', {
                    required: true,
                  })
                    " v-model="client.password" name="password" prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword"
                    :rules="$helpers.rule.notEmpty()" />
                </v-row>
                <v-row>
                  <v-select v-model="client.gender" :items="['m', 'f', 'o']"
                    :label="$helpers.string.capitalizeI18N('gender')" prepend-icon="mdi-gender-male-female">
                    <template v-slot:selection="data">
                      {{ getGenderLabel(data.item) }}
                    </template>
                    <template v-slot:item="data">
                      {{ getGenderLabel(data.item) }}
                    </template>
                  </v-select>
                </v-row>
                <v-row>
                  <v-text-field :label="$helpers.string.capitalizeI18N('phone-number')" v-model="client.phoneNumber"
                    type="phone" prepend-icon="mdi-phone"></v-text-field>
                </v-row>
                <v-row>
                  <v-textarea v-model="client.comment" :label="$helpers.string.capitalizeI18N('comment')"
                    outlined></v-textarea>
                </v-row>
                <v-row class="mt-8">
                  <v-btn color="deep-orange lighten-3" dark @click="handleRegister" :disabled="loading" block>
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>{{ $t("register") }}
                  </v-btn>
                </v-row>

                <v-row class="mt-8">
                  <small>* {{ $t("required-fields") }}</small>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row>
                <v-col>
                  <div>
                    <v-btn text :to="$helpers.router.getOrganizationLink('reset-password')" color="primary lighten-1">{{
                      $t("forgot-password")
                      }}</v-btn>
                  </div>
                  <div>
                    <v-btn class="font-weight-medium" text :to="$helpers.router.getOrganizationLink('login')"
                      color="primary">Vous
                      avez
                      déjà un compte ?</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: "Register",
  data() {
    return {
      showPassword: false,
      client: {},
      loading: false,
      message: "",
      successful: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.adminauth.status.loggedIn;
    },
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  mounted() {
    if (this.$route.query.email) {
      this.client.email = this.$route.query.email;
    }
  },
  methods: {
    getGenderLabel(gender) {
      switch (gender) {
        case "o":
          return this.$helpers.string.capitalizeI18N("gender-o");
        case "m":
          return this.$helpers.string.capitalizeI18N("gender-m");
        case "f":
          return this.$helpers.string.capitalizeI18N("gender-f");
        default:
          return gender;
      }
    },
    handleLogin() {
      //this.$helpers.router.push("admin.login");
    },
    async handleLoginAfterRegistration() {
      this.$store
        .dispatch("publicorganizationauth/login", {
          login: this.client.login,
          password: this.client.password,
        })
        .then(
          () => {
            this.$router.push("/" + this.organizationUrl);
          },
          (error) => {
            this.loading = false;

            this.$helpers.snackbar.handleError(error);
          }
        );
    },
    async handleRegister() {
      if (!this.$refs.form.validate()) return;

      this.message = "";
      this.submitted = true;

      this.$store.dispatch("publicorganizationauth/register", this.client).then(
        (data) => {
          this.client.login = data.login;

          this.handleLoginAfterRegistration();
        },
        (error) => {
          this.successful = false;

          this.$helpers.snackbar.handleError(error);
        }
      );
    },
  },
};
</script>